<template>
  <div class="v-projects-index">
    <router-view />
  </div>
</template>

<script>
import { ViewWithAllProjects } from '@/views/mixins';

export default {
  name: 'VProjectsIndex',
  mixins: [ViewWithAllProjects],
  computed: {
    ready() {
      return this.allProjectsReady;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
